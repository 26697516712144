import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import './SearchForm.css';

const SearchForm = ({
    pickup,
    destination,
    pickupSuggestions,
    destinationSuggestions,
    selectedDate,
    selectedTime,
    setSelectedDate,
    setSelectedTime,
    handlePickupChange,
    handleDestinationChange,
    handleAutocomplete,
    handleSearch,
    control,
    errors,
    isAC,  // New prop to handle AC/Non-AC selection
    setIsAC, // New prop to toggle AC/Non-AC
    
}) => {
    const [showPickupDropdown, setShowPickupDropdown] = useState(false);
    const [showDestinationDropdown, setShowDestinationDropdown] = useState(false);
    
    const handlePickupFocus = () => {
        setShowPickupDropdown(true);
    };

    const handleDestinationFocus = () => {
        setShowDestinationDropdown(true);
    };

    const handlePickupBlur = () => {
        setTimeout(() => setShowPickupDropdown(false), 100); // Delay to allow selection
    };

    const handleDestinationBlur = () => {
        setTimeout(() => setShowDestinationDropdown(false), 100); // Delay to allow selection
    };

    const clearPickup = () => {
        handlePickupChange({ target: { value: '' } });
    };

    const clearDestination = () => {
        handleDestinationChange({ target: { value: '' } });
    };

    return (
        <div className="search-box-banner">
            <form onSubmit={async (e) => {
                e.preventDefault();
                await handleSearch();
            }}>
                <ul className="align-items-center">

                    <li className="column-group-main">
                        <div className="input-block">
                            <div className="ac-toggle-wrapper">
                                <span>Non-AC</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={isAC}
                                        onChange={(e) => setIsAC(e.target.checked)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <span>AC</span>
                            </div>
                            <div className="form-group">
                            </div>
                            <label>Pickup Location</label>

                            <div className="group-img" style={{ position: 'relative' }}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Pickup Location"
                                    value={pickup}
                                    onChange={handlePickupChange}
                                    onFocus={handlePickupFocus}
                                    onBlur={handlePickupBlur}
                                />
                                <span><i className="feather-map-pin" style={{ color: 'green' }}></i></span>
                                {pickup && (
                                    <button
                                        className='button-search'
                                        onClick={clearPickup}
                                        style={{
                                            position: 'absolute',
                                            right: '8px',
                                            top: '40%',
                                            transform: 'translateY(-50%)',
                                            border: '1px solid gray', // Gray border
                                            borderRadius: '25%',  // Make it a round button
                                            fontSize: '19px',  // Smaller font size
                                            cursor: 'pointer',
                                            backgroundColor: '#ffffff', // Transparent background
                                            color: 'gray',  // Gray color for the "✖" symbol
                                            padding: '12px',  // Smaller padding
                                            width: '20px',
                                            height: '29px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        ✖
                                    </button>
                                )}
                                {showPickupDropdown && pickupSuggestions.length > 0 && (
                                    <ul className="custom-dropdown">
                                        {pickupSuggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                onClick={() => {
                                                    handlePickupChange({ target: { value: suggestion.description } });
                                                    handleAutocomplete('pickup', suggestion.description);
                                                    setShowPickupDropdown(false);
                                                }}
                                            >
                                                {suggestion.description}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </li>

                    <li className="column-group-main">
                        <div className="input-block">
                            <label>Destination Location</label>
                            <div className="group-img">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Destination Location"
                                    value={destination}
                                    onChange={handleDestinationChange}
                                    // onBlur={() => handleAutocomplete('destination', destination)}
                                    // list="destination-suggestions"
                                    onFocus={handleDestinationFocus}
                                    onBlur={handleDestinationBlur}
                                />
                                <span><i className="feather-map-pin" style={{ color: 'Red' }}></i></span>
                                {/* <datalist id="destination-suggestions">
                                    {destinationSuggestions.map((suggestion, index) => (
                                        <option key={index} value={suggestion.description}>
                                            {suggestion.description}
                                        </option>
                                    ))}
                                </datalist> */}
                                {destination && <button
                                    onClick={clearDestination}
                                    style={{
                                        position: 'absolute',
                                        right: '8px',
                                        top: '40%',
                                        transform: 'translateY(-50%)',
                                        border: '1px solid gray', // Gray border
                                        borderRadius: '25%',  // Make it a round button
                                        fontSize: '19px',  // Smaller font size
                                        cursor: 'pointer',
                                        backgroundColor: '#ffffff', // Transparent background
                                        color: 'gray',  // Gray color for the "✖" symbol
                                        padding: '12px',  // Smaller padding
                                        width: '20px',
                                        height: '29px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    ✖
                                </button>}
                                {showDestinationDropdown && destinationSuggestions.length > 0 && (
                                    <ul className="custom-dropdown">
                                        {destinationSuggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                onClick={() => {
                                                    handleDestinationChange({ target: { value: suggestion.description } });
                                                    handleAutocomplete('destination', suggestion.description);
                                                    setShowDestinationDropdown(false);
                                                }}
                                            >
                                                {suggestion.description}
                                            </li>
                                        ))}
                                    </ul>
                                )}

                            </div>
                        </div>
                    </li>

                    <li className="column-group-main">
                        <div className="input-block">
                            <label>Pickup Date</label>
                        </div>
                        <div className="input-block-wrapp" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            {/* Date Picker */}
                            <div className="input-block date-widget" style={{ flex: 1 }}>
                                <div className="group-img">
                                    <Controller
                                        control={control}
                                        name="date"
                                        render={({ field }) => (
                                            <DatePicker
                                                className="form-control datetimepicker"
                                                placeholderText="Select date"
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                    setSelectedDate(date); // This line updates the parent component's state
                                                }}
                                                selected={field.value}
                                                dateFormat="MMMM d, yyyy"
                                                minDate={new Date()} // This line restricts selection to future dates
                                                
                                            />
                                        )}
                                        rules={{ required: true }}
                                    />
                                    {errors.date && <span>This field is required</span>}
                                    <span><i className="feather-calendar"></i></span>
                                </div>
                            </div>

                            {/* Time Picker */}
                            <div className="input-block time-widget" style={{ flex: 1 }}>
                                <div className="group-img">
                                    <Controller
                                        control={control}
                                        name="time"
                                        render={({ field }) => (
                                            <DatePicker
                                                className="form-control datetimepicker"
                                                placeholderText="Select time"
                                                onChange={(time) => {
                                                    field.onChange(time);
                                                    setSelectedTime(time);  // This line updates the parent component's state
                                                }}
                                                selected={field.value}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                                
                                            />
                                        )}
                                        rules={{ required: true }}
                                    />
                                    {errors.time && <span>This field is required</span>}
                                    <span><i className="feather-clock"></i></span>
                                </div>
                            </div>
                        </div>
                    </li>
                    {/* Search Button */}
                    <li className="column-group-last">
                        <div className="input-block">
                            <div className="search-btn">
                                <button type="submit" className="btn search-button" >
                                    <i className="fa fa-search" aria-hidden="true "></i>Search
                                </button>
                                <div className="scroll-arrow">
                                    <FontAwesomeIcon icon={faArrowDown} aria-hidden="true" />

                                </div>

                            </div>
                        </div>
                    </li>
                </ul>
            </form>
        </div>
    );
};

export default SearchForm;
