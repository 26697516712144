// src/api/auth.js
import axios from 'axios';


const api = axios.create({
    baseURL: "https://devgas.astergo.in",
    // baseURL: "http://127.0.0.1:8000",
    // baseURL: process.env.BASE_URL,
});

export const sendOtp = async (credentials) => {
    const response = await api.post('/api/send-otp', credentials);
    // console.log(response.data);
    return response.data;
};

export const verifyOtp = async (credentials) => {
    const response = await api.post('/api/verify-otp', credentials);
    return response.data;
};


export const login = async (credentials) => {
    const response = await api.post('/api/login', credentials);
    // console.log(response.data);
    return response.data;
};

export const register = async (userInfo) => {
    const response = await api.post('/api/register', userInfo);
    return response.data;
};
