
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Header from '../components/Main/Header';
import GeocodeComponent from '../components/Main/GeocodeComponent';
// import Footer from '../components/Main/Footer';
// import WelcomePage from '../components/Main/WelcomePage';
// import Slider1 from '../components/Main/Slider1';
// import Slider2 from '../components/Main/Slider2';


const HomePage = () => {
    const token = useSelector((state) => state.auth.token);

    if (!token) {
        return <Navigate to="/OtpLogin" />;
    }

    return (
        <div>
            <Header />
            <GeocodeComponent />
        </div>
    );
};

export default HomePage;
