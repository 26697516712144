// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
// import LoginPage from './pages/LoginPage';
// import RegisterPage from './pages/RegisterPage';
import OtpLogin from './pages/OtpLogin';
import BookingSummary from './components/Booking/BookingSummary';
import BookingHistory from './components/Booking/BookingHistory';
import BookingStatus from './components/Booking/BookingStatus';
import Policy from './components/Main/Policy';
import Profile from './components/Main/Profile';
import ContactUs from './components/Main/ContactUs';
import Header from './components/Main/Header';
import GeocodeComponent from './components/Main/GeocodeComponent';
import Onboarding from './components/Onboarding/Onboarding';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Onboarding />} /> {/* Show onboarding first */}
      <Route path="/home" element={<HomePage />} />
      {/* <Route path="/login" element={<LoginPage />} /> */}
      <Route path="/OtpLogin" element={<OtpLogin />} />
      {/* <Route path="/register" element={<RegisterPage />} /> */}
      <Route path="/booking-status" element={<BookingStatus />} />
      <Route path="/booking-summary" element={<BookingSummary />} />
      <Route path="/booking-history" element={<BookingHistory />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/header" element={<Header />} />
      {/* <Route path="/dashboard" element={<><Header /><GeocodeComponent/></>} /> Dashboard route */}
      {/* <Route path="/slider2" element={<Slider2 />} /> */}

    </Routes>
  );
};

export default App;
