import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './BookingStatus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMapMarkerAlt, faUser, faCalendarAlt, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

const BookingStatus = () => {
    const [activeStep, setActiveStep] = useState(3);
    
    const pickupLocation = 'Purulia Railway Station';
    const pickupDate = 'September 29, 2024, 1:40 PM';
    const destinationLocation = 'Asansol Bus Stand';
    const driverName = 'Ravi Kumar';

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <div className="booking-status-container">
            <div className="status-header">
                <Link to="/" className="back-arrow">
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                <h1>Booking Status</h1>
            </div>

            <div className="status-details">
                {/* Step 1 */}
                <div
                    className={`detail-item ${activeStep === 1 ? 'current' : activeStep > 1 ? 'previous' : ''}`}
                    onClick={() => handleStepChange(1)}
                >
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="detail-icon" />
                    <p><strong>Pickup Location:</strong> {pickupLocation}</p>
                </div>

                {/* Step 2 */}
                <div
                    className={`detail-item ${activeStep === 2 ? 'current' : activeStep > 2 ? 'previous' : ''}`}
                    onClick={() => handleStepChange(2)}
                >
                    <FontAwesomeIcon icon={faUser} className="detail-icon" />
                    <p><strong>Driver:</strong> {driverName}</p>
                </div>

                {/* Step 3 */}
                <div
                    className={`detail-item ${activeStep === 3 ? 'current' : activeStep > 3 ? 'previous' : ''}`}
                    onClick={() => handleStepChange(3)}
                >
                    <FontAwesomeIcon icon={faCalendarAlt} className="detail-icon" />
                    <p><strong>Pickup Date:</strong> {pickupDate}</p>
                </div>

                {/* Step 4 */}
                <div
                    className={`detail-item ${activeStep === 4 ? 'current' : activeStep > 4 ? 'previous' : ''}`}
                    onClick={() => handleStepChange(4)}
                >
                    <FontAwesomeIcon icon={faFlagCheckered} className="detail-icon" />
                    <p><strong>Destination:</strong> {destinationLocation}</p>
                </div>
            </div>
        </div>
    );
};

export default BookingStatus;
