import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; // Import the left arrow icon
import './Profile.css'; // Make sure this includes the updated responsive styles

const Profile = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="profile-container">
            {/* Header */}
            <div className="profile-header">
                <Link to="/" className="back-arrow"> {/* Use Link for navigation */}
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                
            </div>

            {/* Profile Picture Section */}
            <div className="profile-picture-container">
                <div className="profile-icon">
                    <img src="/assets/img/avatar.png" alt="Profile" />
                </div>
                <button className="edit-profile-btn">✏️</button> {/* Edit Icon */}
            </div>

            {/* Profile Details */}
            <h2>Profile details</h2>
            <p>Let’s fill in the basic details of your profile</p>

            {/* Form Fields */}
            <div className="profile-form">
                {/* Name */}
                <div className="input-group">
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Name"
                    />
                </div>

                {/* Email Address */}
                <div className="input-group">
                    <label>Email Address</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Email Address"
                    />
                </div>

                {/* Phone Number */}
                <div className="input-group">
                    <label>Phone Number</label>
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        placeholder="Phone Number"
                    />
                </div>

                
            </div>

            {/* Buttons */}
            <div className="action-buttons">
                <button className="skip-button">Skip</button>
                <button className="proceed-button">Proceed</button>
            </div>
        </div>
    );
};

export default Profile;
