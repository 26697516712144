import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingHistory } from '../../features/booking/bookingSlice';
import { useNavigate } from 'react-router-dom';
import './BookingHistory.css';

const BookingHistory = () => {
    const dispatch = useDispatch();
    const { bookingHistory, status, error } = useSelector((state) => state.bookings);
    const { id } = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        dispatch(getBookingHistory(id)).finally(() => setLoading(false));
    }, [dispatch, id]);

    if (status === 'loading' || loading) {
        return (
            <div className="skeleton-loader">
                {/* Modern loading skeleton */}
                {[...Array(3)].map((_, idx) => (
                    <div key={idx} className="skeleton-card">
                        <div className="skeleton-time"></div>
                        <div className="skeleton-location"></div>
                        <div className="skeleton-price"></div>
                    </div>
                ))}
            </div>
        );
    }

    if (error) {
        return <p>{error}</p>;
    }

    // Sort bookingHistory by booking.id in descending order
    const sortedBookingHistory = [...bookingHistory].sort((a, b) => b.id - a.id);

    return (
        <div className="booking-container">
            {/* Close button */}
            <button className="close-btn" onClick={() => navigate('/')}>×</button>
            <h2 className="booking-history-title">Booking History</h2>
            <div className="booking-history-list">
                {sortedBookingHistory.map((booking) => (
                    <div key={booking.id} className="booking-card">
                        {/* Left side: Time and Location */}
                        <div className="time-info">
                            <div className="time-details">
                                <p className="time-text">{booking.pickup_time}</p>
                                <p className="location-text">
                                    <i className="fa-solid fa-location-arrow" style={{ color: '#FFD43B', marginRight: '8px' }}></i>
                                    {booking.pickup_location}
                                </p>
                            </div>
                            <div className="time-details">
                                <p className="location-text">
                                    <i className="fa-solid fa-location-dot" style={{ color: '#ed1707', marginRight: '8px' }}></i>
                                    {booking.dropoff_location}
                                </p>
                            </div>
                        </div>

                        {/* Right side: Duration, Price, and Button */}
                        <div className="additional-info">
                            <p className="duration">{booking.duration}</p>
                            <p className="price">₹{booking.amount}</p>
                            <button className="book-now-btn" onClick={() => navigate(`/booking/${booking.id}`)}>Book Ride</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BookingHistory;
